import {RoleGuardProps} from 'types/auth';
import {useAuth} from "react-oidc-context";
import {useEffect} from "react";
import {getClientRoles} from "../../services/auth/OidcService";
import {useNavigate} from "react-router-dom";

const RolesGuard = ({roles = [], children}: RoleGuardProps) => {

  const {isAuthenticated, signinRedirect, isLoading, user} = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (!isAuthenticated && !isLoading) {
      const urlParams = new URLSearchParams(window.location.search);
      let extraParams: any = {};
      urlParams.forEach((value, key) => {
        extraParams[key] = value;
      });
      const currentUrl = window.location.href;
      if (!extraParams['session_state']) {
        // redirect to signin only if it's not a callback from the signin with session_state in it
        signinRedirect({redirect_uri: currentUrl, extraQueryParams: extraParams});
      } else {
        // the root cause of hitting this point is unknown
        // anyway we need to stop the infinite loop of signin redirections
        navigate('/', { replace: true });
        window.location.reload();
      }
    }
  }, [isAuthenticated, isLoading, signinRedirect, navigate]);

  if (!isAuthenticated) {
    return null;
  }
  if (roles) {
    let clientRoles = getClientRoles(user);
    const hasRoles = roles.every((role) => clientRoles.includes(role));
    if (!hasRoles) {
      console.warn('[RoleGuard]: user is not authorized for roles=', roles)
      return (null);
    }
  }
  return children;
};

export default RolesGuard;
