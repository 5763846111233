// project import
import Routes from 'routes';
import ThemeCustomization from 'themes';
import Locales from 'components/Locales';
import RTLLayout from 'components/RTLLayout';
import ScrollTop from 'components/ScrollTop';
import Snackbar from 'components/@extended/Snackbar';
import {AuthProvider} from "react-oidc-context";
import {KEYCLOAK_CONFIG} from "./config";
import { getClientRoles } from 'services/auth/OidcService';
import {useNavigate} from "react-router-dom";
// ==============================|| APP - THEME, ROUTER, LOCAL  ||============================== //

const App = () => {

  const navigate = useNavigate();

  return (
      <ThemeCustomization>
        <RTLLayout>
          <Locales>
            <ScrollTop>
              <AuthProvider {...KEYCLOAK_CONFIG} onSigninCallback={user => {
                if (!getClientRoles(user!).includes('signed_borrower')) {
                  navigate('/application');
                }
              }}>
                <>
                  <Routes/>
                  <Snackbar/>
                </>
              </AuthProvider>
            </ScrollTop>
          </Locales>
        </RTLLayout>
      </ThemeCustomization>
  );
}

export default App;
